@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");

.about-me p,
.about-me li,
.about-me span,
.about-me label,
.about-me input,
.about-me textarea {
  color: var(--secondaryText);
  font-family: "Roboto Mono", monospace;
}

.link_a {
  text-decoration: none !important;
  color: #17a2b8;
  cursor: pointer;
}

.about-me ul {
  list-style: none;
}

.heading_1 {
  font-size: 46px !important;
}
.heading_2 {
  font-size: 36px !important;
}
.heading_3 {
  font-size: 28px !important;
}
.heading_4 {
  font-size: 24px !important;
}
.heading_5 {
  font-size: 20px !important;
}
.heading_6 {
  font-size: 16px !important;
}

.about-me .heading {
  color: var(--mainText) !important;
  font-family: "Russo One", sans-serif !important;
  font-weight: 500 !important;
}

.s1 {
  background-color: var(--mainColor);
  border-bottom: 1px solid var(--borderColor);
  overflow: auto;
}

.s2 {
  background-color: var(--secondaryColor);
  border-bottom: 1px solid var(--borderColor);
  overflow: auto;
}

.main-container {
  width: 1200px;
  margin: 0 auto;
}

.greeting-wrapper {
  display: grid;
  text-align: center;
  align-content: center;
  min-height: 10em;
}

.intro-wrapper {
  background-color: var(--secondaryColor);
  border: 1px solid var(--borderColor);
  border-radius: 5px 5px 0 0;

  -webkit-box-shadow: -1px 1px 3px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 3px -1px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 1px 3px -1px rgba(0, 0, 0, 0.75);

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "nav-wrapper nav-wrapper"
    "left-column right-column";
}

.nav-wrapper {
  border-radius: 5px 5px 0 0;
  grid-area: nav-wrapper;
  border-bottom: 1px solid var(--borderColor);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--mainColor);
}

#navigation a {
  color: var(--mainText);
}

#navigation {
  margin: 0;
  padding: 10px;
}

#navigation li {
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
}

.dots-wrapper {
  display: flex;
  padding: 10px;
}

#dot-1 {
  background-color: #fc6058;
}

#dot-2 {
  background-color: #fec02f;
}

#dot-3 {
  background-color: #2aca3e;
}

.browser-dot {
  background-color: black;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin: 5px;

  -webkit-box-shadow: -1px 1px 3px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 3px -1px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 1px 3px -1px rgba(0, 0, 0, 0.75);
}

.left-column {
  grid-area: left-column;
  padding-top: 50px;
  padding-bottom: 50px;
}

#profile_pic {
  display: block;
  margin: 0 auto;
  height: 225px;
  width: 225px;
  object-fit: cover;
  border: 2px solid var(--borderColor);
  box-shadow: 1px 6px 12px 8px var(--box-shadow);
}

#theme-options-wrapper {
  display: flex;
  justify-content: center;
}

.theme-dot {
  height: 30px;
  width: 30px;
  background-color: black;
  border-radius: 50%;
  margin: 5px;
  border: 2px solid var(--themeDotBorder);
  -webkit-box-shadow: -1px 1px 3px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 3px -1px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 1px 3px -1px rgba(0, 0, 0, 0.75);
  cursor: pointer;
  transition: all 0.3s ease;
}

.theme-dot:hover {
  transform: scale(1.2);
}

#light-mode {
  background-color: #fff;
}

#blue-mode {
  background-color: #192734;
}

#green-mode {
  background-color: #78866b;
}

#purple-mode {
  background-color: #7e4c74;
}

#settings-note {
  font-size: 8px;
  font-style: italic;
  text-align: center;
}

.right-column {
  grid-area: right-column;
  display: grid;
  align-content: center;

  padding-top: 50px;
  padding-bottom: 50px;
}

#preview-shadow {
  background-color: var(--previewShadow);
  max-width: 300px;
  height: 180px;
  padding-left: 30px;
  padding-top: 30px;
}

#preview {
  width: 300px;
  border: 1.5px solid #17a2b8;
  background-color: var(--previewBg);
  padding: 15px;
  position: relative;
}

.corner {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  border: 1.5px solid #17a2b8;
  background-color: #fff;
  position: absolute;
}

#corner-tl {
  top: -5px;
  left: -5px;
}

#corner-tr {
  top: -5px;
  right: -5px;
}

#corner-br {
  bottom: -5px;
  right: -5px;
}

#corner-bl {
  bottom: -5px;
  left: -5px;
}

.about-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  padding-bottom: 50px;
  padding-top: 50px;
  gap: 100px;
}

#skills {
  display: flex;
  justify-content: space-evenly;
  background-color: var(--previewShadow);
  margin-top: 0.3rem;
  padding: 1rem;
}

#skills > ul {
  margin: 0;
}

.social-links {
  display: grid;
  align-content: center;
  text-align: center;
}

#social_img {
  width: 100%;
}

.post-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, 320px);
  gap: 20px;
  justify-content: center;
  padding-bottom: 50px;
}

.post {
  border: 1px solid var(--borderColor);
  -webkit-box-shadow: -2px 7px 21px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -2px 7px 21px -9px rgba(0, 0, 0, 0.75);
  box-shadow: -2px 7px 21px -9px rgba(0, 0, 0, 0.75);
}

.thumbnail {
  display: block;
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.post-preview {
  background-color: #fff;
  padding: 15px;
}

.post-title {
  color: black;
  margin: 0;
}

.post-intro {
  color: #4b5156;
  font-size: 14px;
}

#contact-form {
  display: block;
  max-width: 600px;
  margin: 0 auto;
  border: 1px solid var(--borderColor);
  padding: 15px;
  border-radius: 5px;
  background-color: var(--mainColor);
  margin-bottom: 50px;
}

#contact-form label {
  line-height: 2.7em;
}

#contact-form textarea {
  min-height: 100px;
  font-size: 14px;
}

.input-field {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: var(--secondaryColor);
  border-radius: 5px;
  border: 1px solid var(--borderColor);
  font-size: 14px;
}

#submit-btn {
  margin-top: 10px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fff;
  background-color: var(--buttonColor);
  border: none;
}

@media (max-width: 1200px) {
  .main-container {
    width: 95%;
  }
}

@media (max-width: 800px) {
  .intro-wrapper {
    grid-template-columns: 1fr;
    grid-template-areas:
      "nav-wrapper"
      "left-column"
      "right-column";
  }

  .heading_1 {
    font-size: 1.5rem !important;
  }

  .right-column {
    justify-content: center;
  }
}

@media (max-width: 400px) {
  #preview-shadow {
    max-width: 280px;
    height: 180px;
    padding-left: 10px;
    padding-top: 10px;
  }

  #preview {
    width: 280px;
  }
}

.light-theme {
  --mainColor: #eaeaea;
  --secondaryColor: #fff;

  --borderColor: #c1c1c1;

  --mainText: black;
  --secondaryText: #4b5156;

  --themeDotBorder: #24292e;

  --previewBg: rgb(251, 249, 243, 0.8);
  --previewShadow: #f0ead6;

  --buttonColor: black;
}

.purple-theme {
  --mainColor: #46344e;
  --secondaryColor: #382a3f;

  --borderColor: #1d1520;

  --mainText: #fff;
  --secondaryText: #eeeeee;

  --themeDotBorder: #fff;

  --previewBg: rgb(29, 21, 32, 0.8);
  --previewShadow: #2b202f;

  --buttonColor: #8534a3;
}

.blue-theme {
  --mainColor: #15202b;
  --secondaryColor: #192734;

  --borderColor: #164d56;

  --mainText: #fff;
  --secondaryText: #eeeeee;

  --themeDotBorder: #fff;

  --previewBg: rgb(25, 39, 52, 0.8);
  --previewShadow: #111921;

  --buttonColor: #17a2b8;
}

.green-theme {
  --mainColor: #606b56;
  --secondaryColor: #515a48;

  --borderColor: #161914;

  --mainText: #fff;
  --secondaryText: #eeeeee;

  --themeDotBorder: #fff;

  --previewBg: rgb(81, 90, 72, 0.8);
  --previewShadow: #2a2f25;

  --buttonColor: #669966;
}
