.alerts {
  position: fixed;
  top: 100px;
  right: 20px;
  z-index: 99;
  /* height: 80vh;
  padding: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  pointer-events: none; */
}

.alert {
  width: 350px;
  padding: 0.5rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fafafa;
  border-radius: 10px;
  box-shadow: 2px 4px 9px 0px #656161;
}

.alert > i {
  max-width: 70px;
  /* height: auto; */
  max-height: 100px;
  object-fit: contain;
  padding: 0.8rem;
}

.alert > p {
  margin: 0;
}

.alert > p > span {
  color: #e4ae25;
}

.alert > .close {
  cursor: pointer;
  pointer-events: all;
}
