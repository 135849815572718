/* @import "https://fonts.googleapis.com/css2?family=Montserrat&display=swap"; */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body * {
  transition: background 0.3s;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}

img {
  width: 100%;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container {
  max-width: 1100px;
  overflow: hidden;
  margin: auto;
  padding: 0.2rem 1rem 1rem;
  width: 95%;
}
img {
  width: 100%;
}
.btn {
  text-decoration: none;
  color: #333;
  padding: 0.25rem 1rem;
  border-radius: 20px;
  font-size: 1.1rem;
  font-weight: 535;
}
.btn_primary {
  background-color: #264aca;
  color: #f2f2f2;
  transition: background 0.5s ease;
  font-size: 1.1rem;
}
.btn_primary:hover {
  background-color: #123fca;
  color: #fff;
}
.btn_secondary {
  text-align: center;
  border: 1px solid #264aca;
  color: #264aca;
  margin: 0 0 0 5px;
  transition: background 0.5s ease;
}
.btn_secondary:hover {
  color: #f2f2f2;
  background-color: #264aca;
}
.btn_third {
  color: #f2f2f2;
}
.btn_work {
  text-decoration: none;
  color: #f2f2f2;
  padding: 0.25rem 0;
  font-size: 1.1rem;
  font-weight: 535;
}
.work_btn {
  font-size: 1rem;
  font-weight: 450;
}
.work_btn:focus,
.work_btn:active {
  border: none;
  outline: 0;
}
.work_btn:hover:not(.active) {
  color: #1e90ff;
}
.btn_block {
  display: block !important;
  border: 0;
  text-align: center;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #264aca;
  background-color: #264aca;
  color: #dfdddd;
}
.upBtn {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  color: #264aca;
  z-index: 999;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container {
  max-width: 1100px;
  overflow: hidden;
  margin: auto;
  padding: 0.2rem 1rem 1rem;
  width: 95%;
}
img {
  width: 100%;
}
.mouse {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 3px;
  position: absolute;
  bottom: 7%;
  left: 50%;
  transform: translateX(-50%);
}
.mouse-icon {
  width: 25px;
  height: 45px;
  border: 2px solid #264aca;
  border-radius: 15px;
  cursor: pointer;
  position: relative;
  text-align: center;
}
.mouse-wheel {
  height: 6px;
  margin: 2px auto 0;
  display: block;
  width: 3px;
  background-color: #264aca;
  border-radius: 50%;
  -webkit-animation: 1.6s ease infinite wheel-up-down;
  -moz-animation: 1.6s ease infinite wheel-up-down;
  animation: 1.6s ease infinite wheel-up-down;
}
.mouse-text-content {
  font-size: 0.8rem;
  font-weight: bold;
  color: #264aca;
  text-transform: uppercase;
}

@-webkit-keyframes wheel-up-down {
  0% {
    margin-top: 2px;
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  100% {
    margin-top: 20px;
    opacity: 0;
  }
}
@-moz-keyframes wheel-up-down {
  0% {
    margin-top: 2px;
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  100% {
    margin-top: 20px;
    opacity: 0;
  }
}
@keyframes wheel-up-down {
  0% {
    margin-top: 2px;
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  100% {
    margin-top: 20px;
    opacity: 0;
  }
}
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Regular.ttf");
}
@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-Regular.ttf");
}
h1,
h2,
h3,
h4 {
  font-family: "Montserrat", sans-serif;
}
.loader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: hidden;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  transition: opacity 0.4s ease;
}
.loader img {
  width: 250px;
  height: 50px;
  display: block;
}
.loader .img1 {
  height: 250px;
}
#loader.finished {
  opacity: 0;
  pointer-events: none;
  display: none;
}
#loader.finished img {
  display: none;
}
body {
  background-color: #f4f4f9;
  font-family: "Poppins", sans-serif;
}

.App {
  visibility: hidden;
}
.App.show {
  visibility: visible;
}
#navbar {
  position: sticky;
  top: 0;
  z-index: 99;
  min-height: 5vh;
  background-color: #33393b;
  display: flex;
  align-items: center;
  /* flex-direction: column; */
}

#navbar .img-container {
  height: 30px;
  width: 150px;
}

#navbar .container {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#navbar .container nav {
  display: flex;
  width: 50%;
  justify-content: space-around;
}

/* #navbar. .container a > img {
  width: 150px;
  object-fit: contain;
} */

#navbar .container nav > a {
  text-decoration: none;
}

#navbar .container nav > a:hover {
  color: #fff;
  box-shadow: 0px 2px 3px 2px #252525;
}

#navbar .container > nav.nav-mobile {
  display: none;
  color: #dbdada;
}
/* #navbar. .container i.fas {
  display: none;
  color: #dbdada;
} */
header {
  min-height: 5vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
header .img-container {
  height: 30px;
  width: 150px;
}

header .img-container img {
  z-index: 99;
}

header nav {
  display: flex;
  width: 50%;
  justify-content: space-around;
}
section.home_section {
  height: 90vh;
  display: grid;
  grid-template-columns: 6fr 4fr;
  background-image: url("./imgs/home_bg_image.png");
  background-position: 100% 60%;
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
}
section.home_section .section_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
section.home_section .section_content .navigation .btn {
  font-size: 0.9rem;
  padding: 0.6rem 1.5rem;
}
section.home_section .section_content .heading {
  margin-bottom: 2rem;
}
section.home_section .section_content .heading .heading__text {
  font-size: 2.1rem;
  font-weight: 900 !important;
  margin-bottom: 1.3rem;
  letter-spacing: 0.5px;
}
section.home_section .section_content .heading .heading__greeting,
section.home_section .section_content .heading .heading__presentation {
  font-size: 1.5rem;
  color: #545151;
  letter-spacing: 2px;
}
section.home_section .section_content .heading .heading__greeting {
  margin: 0;
  padding: 0;
}
section.home_section
  .section_content
  .heading
  .heading__presentation__activity {
  display: inline-block;
  position: relative;
  text-align: left;
  border-bottom: 2.5px solid #264aca;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
section.home_section .mobile_container {
  display: none;
}

.my_activity {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: 3rem 0 4rem;
}

.my_activity__card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px #f5f5f51a;
  padding: 20px;
  width: 300px;
  text-align: center;
  position: relative;
}

.my_activity__icon {
  font-size: 40px;
  color: #fff;
  margin-bottom: 15px;
}

.my_activity__title {
  margin-top: 0;
  font-size: 20px;
  font-weight: 600;
  /* color: #333333; */
}

.my_activity__description {
  color: #666666;
  font-size: 16px;
  line-height: 1.5;
  margin-top: 12px;
}

@media (max-width: 768px) {
  .my_activity {
    flex-direction: column;
    align-items: center;
  }

  .my_activity__card {
    width: 100%;
  }
}

.work_section {
  margin-top: 1.5rem;
}
.work_section h1.heading {
  text-align: center;
  color: #264aca;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 0.75rem;
  font-family: "Montserrat", sans-serif !important;
}
.work_section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.work_section-container .work_heading {
  margin: 1.5rem 0 0;
}
.work_section-container .work_heading img,
.work_section-container .work_heading svg {
  width: 250px;
  height: 250px;
}
.work_section-container .work_menu {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 2.5rem 0 2.5rem;
  font-size: 1rem;
  text-align: center;
}
.work_section-container .work_menu a {
  cursor: pointer;
}
.work_section-container .work_menu a:not(.btn_primary):hover {
  color: dodgerblue;
}
.work_section-container .my_works {
  display: flex;
}

.work_section-container .my_works {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.5rem;
  margin-bottom: 1rem;
}

.work_section-container .my_works .card {
  padding: 1rem;
  border-radius: 1rem;
  background-color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transform: translateY(0px);
  text-align: center;
  overflow: hidden;
  cursor: pointer;
}

.work_section-container .my_works .card .img-container {
  margin-bottom: 16px;
}
.work_section-container .my_works .card .img-container img {
  max-height: 200px;
  object-fit: contain;
}
.work_section-container .my_works .card:hover {
  box-shadow: 0px 6px 31px -2px rgba(0, 0, 0, 0.1);
  transform: translateY(-10px);
  transition: transform 0.35s ease;
}
.footer {
  height: 9vh;
  background-color: #33393b;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
}
.footer .footer_content h1 {
  letter-spacing: 5px;
  font-size: 1.2rem;
  text-align: center;
}
.footer .social_media {
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-width: 25vw;
}
.footer .social_media .social_media__links {
  cursor: pointer;
  font-size: 1.2rem;
  color: #c9c9c9;
  text-decoration: none;
}
.footer .social_media .social_media__links:hover i {
  font-size: 2rem;
}
.footer .social_media i {
  transition: all 0.3s ease;
  pointer-events: none;
}
.form_submission .container {
  display: grid;
  grid-template-columns: 3.4fr 2fr;
  align-items: center;
  justify-content: center;
  height: 83vh;
  width: 100%;
}
.form_submission__heading {
  font-size: 2.5rem;
  color: #3f5ee9;
  text-align: center;
  margin-bottom: 25px;
  font-weight: bold;
  letter-spacing: 1px;
  position: relative;
  /* text-transform: uppercase; */
}

.form_submission__heading::after {
  content: "";
  display: block;
  width: 50%; /* Adjust width as needed */
  height: 2px; /* Thickness of the line */
  background-color: #3f5ee9; /* Same color as the heading */
  margin: 15px auto 0 auto; /* Space above the line and center it */
}

.form_submission .container .form {
  padding: 2rem;
}
.form_submission .container .form-control {
  margin-bottom: 1rem;
}
.form_submission .container .form-control label {
  display: block;
}
.form_submission .container .form-control input {
  width: 100%;
  padding: 0.5rem;
  border: 0;
  border-radius: 3px;
}
.form_submission .container .form-control input:focus,
.form_submission .container .form-control textarea:focus {
  outline: none;
}
.form_submission .container .form-control textarea {
  width: 100%;
  height: 4rem;
  border: 0;
}
.form_submission .container .form button:focus {
  outline: none;
  border: 0;
}
.form_submission .container .form button:active {
  transform: scale(0.98);
}
.form_submission .container .video_container {
  max-width: 400px;
}
.form_submission .container .video_container .video {
  border-radius: 2.5rem;
  height: 100%;
  width: 100%;
}
#man {
  transform: translateY(-700px);
  transition: transform 1.5s ease;
}
#man.show {
  transform: translateY(0px);
}
#list {
  transform: translateX(700px);
  opacity: 0;
  transition: all 1.5s ease 0.7s;
}
#list.show {
  transform: translateX(0px);
  opacity: 1;
}
#left_hand {
  transform: translate(-25px) rotateX(20deg);
  transition: all 1s ease 1s;
}
#left_hand.show {
  transform: translate(0px) rotateX(0deg);
}
.active {
  border-bottom: 2px solid #264aca;
}
@media (max-width: 860px) {
  #navbar .container nav {
    display: none;
  }
  /* #navbar .container i.fas {
    display: block;
  } */
  #navbar .container > nav.nav-mobile {
    display: block;
  }
  .language-switcher {
    margin-right: 50px;
  }
  .footer .footer_content h1 {
    font-size: 1rem;
  }
  .footer .social_media {
    min-width: 50vw;
  }
  .work_section .my_works {
    grid-template-columns: repeat(2, 1fr);
  }
  section.home_section {
    grid-template-columns: 3fr 1fr;
    align-items: center;
    overflow: hidden;
  }
}
.upBtn.show {
  pointer-events: all;
  transform: translateX(150%);
  transition: transform 0.5s ease;
}
.upBtn {
  transform: translate(0%);
}
@media (max-width: 823px) {
  .btn {
    font-size: 0.9rem;
  }
  .mouse {
    bottom: 0;
  }
  .mouse .mouse-icon {
    width: 20px;
    height: 35px;
  }
  section.home_section {
    background: none;
    grid-template-columns: 1fr;
    grid-template-rows: 4fr 1fr;
    align-items: center;
    overflow: hidden;
    text-align: center;
  }
  section.home_section .mobile_container {
    display: block;
    padding-bottom: 1rem;
  }
  section.home_section .mobile_img {
    display: block;
    width: 90%;
    margin: auto;
    object-fit: contain;
  }
  section.home_section .section_content {
    margin-top: 5rem;
  }
  section.home_section .section_content h1 {
    font-size: 1.7rem !important;
  }
  section.home_section .section_content p {
    font-size: 1.3rem !important;
  }
  section.home_section .navigation {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  section.home_section .navigation a:first-of-type {
    font-size: 1.1rem;
    margin-bottom: 1rem;
  }
}
@media (max-width: 600px) {
  .work_section .my_works {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 664px) {
  .work_section-container .work_menu {
    margin: 2.5rem 0 1.5rem;
  }
}

@media (max-width: 650px) {
  .table-column {
    font-size: 10px;
  }

  .form_submission .container {
    grid-template-columns: 1fr;
  }
  .form_submission .container .video_container {
    display: none;
  }
}
@media (max-width: 466px) {
  .mouse .mouse-icon {
    width: 20px;
    height: 35px;
  }
  section.home_section .navigation {
    display: flex;
    flex-direction: column;
  }
  section.home_section .navigation a {
    font-size: 1.1rem;
    margin-bottom: 1rem;
  }
}

@media (min-height: 720px) {
  .form_submission .container {
    height: 83vh;
  }
}
@media (min-height: 900px) {
  .form_submission .container {
    height: 85vh;
  }
}

@media (max-height: 568px) {
  section.home_section .section_content {
    margin-top: 2.5rem;
  }
  section.home_section a {
    font-size: 0.7rem !important;
    margin-bottom: 1rem;
  }
  section.home_section .section_content h1 {
    font-size: 1rem !important;
  }
  section.home_section .section_content p {
    font-size: 1rem !important;
  }
  .footer .footer_content h1 {
    font-size: 0.75rem;
  }
  .footer .social_media i {
    font-size: 0.75rem;
  }
}
@media (max-height: 667px) {
  section.home_section .section_content {
    margin-top: 2.3rem;
  }
  section.home_section a {
    font-size: 0.6rem;
    margin-bottom: 1rem;
  }
  section.home_section .section_content h1 {
    font-size: 1.3rem !important;
  }
  section.home_section .section_content p {
    font-size: 1rem !important;
  }
  .footer .footer_content h1 {
    font-size: 0.75rem;
  }
  .footer .social_media i {
    font-size: 0.75rem;
  }
}

@media (max-height: 731px) {
  section.home_section .section_content {
    margin-top: 1.6rem;
  }
}
@media (max-height: 823px) {
  section.home_section .section_content {
    margin-top: 1.3rem;
  }
}

#projects
  > div
  > div.position-relative
  > div
  > div.contrib-footer.clearfix.mt-1.mx-3.px-3.pb-1
  > div.float-left.text-gray {
  color: #eee;
}

/*# sourceMappingURL=style.css.map */

.projectDetail {
  margin: auto;
  width: 80vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem 1rem;
}

.projectDetail__header {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.projectDetail__badges {
  min-width: 220px;
  display: flex;
  justify-content: space-between;
}

.projectDetail__heading {
  letter-spacing: 3px;
  color: #524f4f;
  font-size: 1.6rem;
  margin: 0.5rem 0;
}

.page__container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.webpage__iframe {
  margin-top: 1rem;
  border-radius: 5px;
}

.projectDetail__Image {
  display: block;
  width: 85%;
  max-height: 650px;
  min-width: 60%;
  object-fit: contain;
  margin: 1.5rem 0;
}

.projectDetail__Description {
  padding: 1rem;
  text-align: center;
}

.projectDetail__Description > h3 {
  font-size: 1.5rem;
  color: #524f4f;
}

.projectDetail__Description > h3,
.projectDetail__Description > p {
  margin-bottom: 1rem;
  text-align: center;
}
.projectDetail__Description > blockquote > p {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}

.projectDetail__Description img {
  width: 100px;
  object-fit: contain;
  margin-left: 1rem;
}

.projectDetail__Description > blockquote > p > img {
  width: 100px;
  object-fit: contain;
  margin-left: 1rem;
}

.projectDetail__Description > blockquote > ul,
.projectDetail__Description > blockquote > ol {
  margin: 1rem 0;
}

.projectDetail__Description > blockquote > ul > li {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8px;
}

.projectDetail__Description > blockquote > ol {
  width: fit-content;
  margin: auto;
}

.projectDetail__langages {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 1rem;
}

.projectDetail__langagesHeading {
  font-size: 1.1rem;
}

.projectDetail__langagesIcones {
  margin-left: 1rem;
}
.projectDetail__BackendsIcones,
.projectDetail__langagesIcones {
  display: flex;
  align-items: center;
}

.projectDetail__BackendsIcones img,
.projectDetail__langagesIcones img {
  width: 80px;
  height: 50px;
  object-fit: contain;
  margin-left: 0.75rem;
}

.projectDetail__container {
  display: flex;
  margin-top: 1rem;
}

.fa-html5 {
  color: rgb(236, 159, 14);
}
.fa-css3-alt {
  color: rgb(37, 111, 185);
}

.fa-js {
  color: rgb(238, 192, 40);
}

.projectDetail__langagesIcones i {
  margin: 0 0.5rem;
}

.projectDetail__buttons {
  display: flex;
}

.projectDetail__buttons > button {
  margin: 1rem;
}

.collapseBtn {
  color: rgb(245, 245, 245);
}

@media (max-width: 1361px) {
  .projectDetail__header {
    flex-direction: column;
  }
}

@media (max-width: 787px) {
  .projectDetail {
    width: 100vw;
    padding: 2rem 0.2rem;
  }

  .projectDetail__Image {
    width: 100%;
  }

  .projectDetail__header {
    width: 100%;
  }
  .projectDetail__Description h3 {
    font-size: 1.2rem;
    font-weight: 600;
  }
}
@media (max-width: 661px) {
  .projectDetail__buttons {
    flex-direction: column;
  }

  .projectDetail__buttons button.MuiButtonBase-root {
    /* width: fit-content;
    margin: auto; */
    align-self: center;
  }
}
@media (max-width: 600px) {
  .projectDetail__langagesIcones {
    font-size: 0.75rem;
  }

  .form_submission .container .form {
    padding: 0.5rem;
  }

  /* .footer .footer_content h1 {
    font-size: 0.5rem;
  } */

  .upBtn {
    font-size: 3.2rem;
  }
}

.calendar table {
  border-collapse: separate;
}

/* General styling for the not-found page */
.not_found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh; /* Make sure it covers the full viewport height */
}

.icon-container {
  font-size: 4rem;
  color: #ff4c4c; /* You can choose any color for the icon */
  margin-bottom: 20px;
}

.not_found h1 {
  font-size: 3rem;
  margin-bottom: 20px;
  color: #333;
}

.not_found p {
  font-size: 1.5rem;
  margin-bottom: 30px;
  color: #666;
}

.not_found a {
  font-size: 1.2rem;
  color: #007bff;
  text-decoration: none;
  padding: 10px 20px;
  border: 2px solid #007bff;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.not_found a:hover {
  background-color: #007bff;
  color: #fff;
}

@media screen and (max-width: 768px) {
  /* Adjust font sizes for smaller screens */
  .not_found h1 {
    font-size: 2.5rem;
  }

  .not_found p {
    font-size: 1.2rem;
  }

  .not_found a {
    font-size: 1rem;
  }
}
